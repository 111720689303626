$footer-nav-text: lighten(palette(primary, light), 40%);

.footer {
    @extend .flex-row-center;
    background-color: palette(background, dark-gray);
    box-shadow: inset 0px 5px 5px darken(palette(background, dark-gray), 5%);
    padding: 80px 80px 40px;
    @media (max-width: breakpoints(sm)) {
        padding: 80px 40px 40px;
    }

    .footer-content {
        @extend .default-margins-xl;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .footer-content-left {
            @extend .flex-column;
            @media (max-width: 1330px) {
                width: 100%;
                align-items: center;
                margin-bottom: 20px;
            }

            .logo {
                margin-bottom: 10px;
                width: 250px;
                color: palette(grey, 300);
            }
            .footer-map {
                background: no-repeat center url('../../media/footer/footer-map.png');
                background-size: 100%;
                width: 250px;
                height: 150px;
        
                &:hover {
                    cursor: pointer;
                }
            }
            .social-icon {
                color: palette(grey, 300);
            }
        }
        .footer-content-right {
            @extend .flex-row-center;
            @media (min-width: 1330px) {
                margin-left: 50px;
            }
            @media (max-width: 1330px) {
                width: 100%;
            }
        }
        .footer-content-bottom {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .footer-text {
                font-size: 0.85rem;
                color: $footer-nav-text;
            }
        }
    }
}

.footer-navigation {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    @media (min-width: breakpoints(sm)) {
        justify-content: center;
    }

    &::-webkit-scrollbar-track {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
    }

    .nav-group {
        padding: 10px 30px 10px 0px;
        @media (max-width: breakpoints(sm)) {
            width: 100%;
        }

        .category-title {
            @include typography(button);
            color: $footer-nav-text;
            font-size: 0.85rem !important;
        }

        .underline {
            background: $footer-nav-text;
            border-bottom-width: 2px;
        }

        .route-title {
            color: white !important;
        }

        .route-list-item {
            padding: 2px 0px;
        }

        .MuiListItemText-primary {
            font-size: 0.85rem !important;
        }

        .nav-group-header {
            width: max-content;
        }
    }
}

.footer-navigation::-webkit-scrollbar {
    display: none;
}