.post-details {
    @extend .flex-col-center;
    padding-top: $header-height;

    .post-container {
        @extend .default-margins-xl;
        width: 100%;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .post-top-nav {
        padding: 1% 5%;
    }
    .post-content {
        flex-direction: column;
        padding: 80px 40px;
        @media (max-width: breakpoints(sm)) {
            padding: 30px;
        }

        .post-title {
            @include typography(h2);
            margin-bottom: 20px;
        }
        .post-details-div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .divider {
                height: 25px;
                margin: 0px 20px;
                border-right-width: 2px;
            }
            .post-detail {
                display: flex;
                align-items: center;
            }
            .icon {
                margin-right: 10px;
            }
        }
        .post-hero {
            width: 100%;
            height: auto;
            overflow: hidden;
            margin-bottom: 40px;
        
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .post-tags {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
            margin-top: 10px;
            align-items: center;

            .post-tags-header {
                margin-right: 20px;
            }
        }
        .post-markdown {
            width: 100%;

            tr {
                border-top: 1px solid $post-detail-border;
                background: palette(background, default);
            }
            th, td {
                padding: 6px 13px;
                border: 1px solid $post-detail-border;
            }
            table tr:nth-child(2n) {
                background: $post-detail-table;
            }
            img {
                max-width: 100%;
            }
            blockquote {
                width: 75%;
                border-left: solid $post-detail-border 4px;
                margin: auto;
                padding-left: 20px;
            }
            code {
                background-color: palette(background, dark);
            }
            iframe {
                width: 560px;
                height: 315px;  
                max-width: 100%;
            }
            p {
                line-height: 1.75rem;
            }
            h1, h2, h3, h4, h5, h6 {
                @extend .scroll-anchor;
            }
        }
    }
    .post-bottom-nav {
        padding: 0 5%;
    }
}