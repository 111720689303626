$card-height: 100%;
$thumbnail-height: 200px;

.post-card {
    @extend .flex-column;
    text-transform: none !important;
    text-align: left;
    height: $card-height;

    :hover {
        cursor: pointer;

        .pc-title {
            color: lighten(palette(primary, main), 12%);
        }
    }
    .pc-thumbnail {
        height: $thumbnail-height;
        border-bottom: solid 1px lightgray;
    }
    .pc-content {
        min-height: 200px;
        height: calc($card-height - $thumbnail-height);
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: space-between;

        .pc-title {
            @include typography(h6);
            padding-bottom: 10px;
        }
        .pc-blurb {
            h1, h2, h3, h4, h5, h6, p {
                @include typography(body2);
            }
        }
    }
}

.tag-chip {
    font-weight: 500;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    width: fit-content !important;
    border-width: 2px !important;

    &.margin-right {
        margin-right: 6px;
    }
}