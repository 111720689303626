.contracts {
    @extend .flex-col-center;
    
    .content {
        @extend .flex-column;
        @extend .center-content;
        @extend .default-margins;
        @extend .default-padding;

        .m-div {
            width: 100%;
        }
        .accordion {
            margin-bottom: 20px;
            background-color: white;
            box-shadow: none !important;
            width: 100%;

            @media (max-width: breakpoints(sm)){
                max-width: 400px !important;
            } 

            .accordion-summary {
                border-radius: 2px;
                height: 25px !important; 
                min-height: 50px !important; //keep static height when expanded (override min-height set by MUI)
                color: white;
                background-color: palette(primary, main);

                @media(max-width: breakpoints(sm)){
                    height: min-content !important; //allow accordion to show full content on small screens (more likely to overflow in sm)
                }

                &.default-cursor {
                    cursor: default !important;
                    pointer-events: none;
                }
                .summary-text {
                    @include typography(h6);
                }
                .arrow {
                    color: white; 
                    font-size: 1.75rem; 
                    font-weight: 50;
                }
            }

            .accordion-details {
                padding: 0;

                .ct-title {
                    @extend .title;
                    font-size: 1.5rem !important;
                }
                .body {
                    white-space: pre-line;
                    margin-bottom: 20px;
                }
            }
        }
    }
}