.resources {
    @extend .full-width;
    @extend .center-content;
    background-color: palette(background, light-gray);
    margin-top: 50px;
    padding: 50px 100px;
    margin-bottom: 50px;

    @media (max-width: breakpoints(lg)) {
        padding: 50px 5%;
    }
    @media(max-width: breakpoints(sm)) {
        padding: 50px;
    }
    @media(min-width: breakpoints(xl)) {
        padding: 50px 0px;
    }

    .content {
        @extend .default-margins;
        @extend .flex-column;
        @extend .center-content;
        min-height: 100px;
        width: 100%;

        @media(min-width: breakpoints(lg)) {
            flex-direction: row;
        }

        .resources-header {
            @extend .flex-column;
            
            @media (max-width: breakpoints(lg)) {
                margin-bottom: 20px;
            }

            .title {
                @include typography(h5);
                font-weight: bold !important;
                margin-bottom: 5px;
    
                @media(min-width: breakpoints(lg)) {
                    margin-right: 40px;
                }
            }
            .divider {
                width: 100px;
                height: 3px;
                background-color: palette(text, primary);
                margin-bottom: 10px;
            }
        }
        .card-list {
            align-items: center;
            justify-content: center;
            
            .grid-item {
                
                .card {
                    @extend .flex-column;
                    background-color: white;
                    width: 100%;
                    min-height: 200px;

                    &:hover {
                        box-shadow: 0px 2px 8px palette(grey, 400);
                        cursor: pointer;
                    }
                    .card-action {
                        min-height: 200px;
                        padding: 20px;
                    }
                    .card-title {
                        @include typography(h6);
                        margin-bottom: 20px;
                        color: palette(primary,main);
                        text-transform: uppercase !important;
                    }
                    .card-summary {
                        @include typography(body1);
                    }
                }
            }
        }
        .icon-btn {
            width: 50px;
            height: 50px;
            background: palette(primary,main) !important;
            color: white !important;
        }
    }
}