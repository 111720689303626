.styled-button {
    &.MuiButton-root {
        // disallows full width buttons
        width: max-content;
        &:hover {
            box-shadow: shadows(5);
        }
    }
    &.MuiButton-outlined {
        border-width: 2px !important;
        padding: 12px 20px;
    }
    &.MuiButton-contained {
        // additional padding to match size of outlined button
        padding: 14px 22px;
    }
    &.MuiButton-containedPrimary:enabled {
        background: $primary-gradient;
    }
    &.MuiButton-outlinedPrimary:enabled {
        border-color: palette(primary, light);
        color: palette(primary, main);
        &:hover {
            border-color: palette(primary, light);
        }
    }
    &.MuiButton-containedSecondary:enabled {
        background: $secondary-gradient;
    }
    &.MuiButton-outlinedSecondary:enabled {
        border-color: palette(secondary, light);
        color: palette(secondary, main);
        &:hover {
            border-color: palette(secondary, light);
        }
    }
}