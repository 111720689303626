.page-header {
    @extend .full-width;
    @extend .flex-col-center;
    justify-content: flex-end;
    box-shadow: inset 0 0 0 2000px $page-header-overlay-default;
    height: 40vh;
    background-image: url(../../media/page-header/default.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    margin-top: $header-height;

    @media (max-width: breakpoints(sm)) {
        height: 60vh;
    }

    &.clients-contracts-background {
        box-shadow: inset 0 0 0 2000px $page-header-overlay-dark;
        background-image: url(../../media/page-header/dc-skyline.jpg);
    }

    .content {
        @extend .default-margins;
        @extend .default-padding;
        width: 100%;
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: breakpoints(sm)) {
            align-items: center;
            text-align: center;
        }

        .pre-title {
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-style: italic;
            padding-bottom: 10px;
            font-weight: 500;
            width: 100%;
        }

        .title {
            font-size: 4rem;
            line-height: 4rem;
            font-weight: 500;
            width: 100%;
            padding-bottom: 30px;
            overflow-wrap: break-word;

            @media (max-width: breakpoints(sm)) {
                font-size: 3rem;
                line-height: 3rem;
            }
        }

        .subtitle-div {
            display: flex;
            align-items: flex-start;

            .subtitle {
                font-size: 1.75rem;
                line-height: 1.75rem;
                font-weight: 400;
                width: 100%;
            }

            .icon {
                font-size: 2rem;
                margin-right: 5px;
            }
        }
    }
}