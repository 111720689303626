@import './resources', './why_join_us';

.careers {
    @extend .flex-col-center;
    padding-bottom: 50px;
    overflow: auto;
}

.career-summary {
    @extend .default-margins;
    @extend .default-padding;
    @extend .flex-column;
    padding-bottom: 100px !important;
    justify-content: space-between;

    .content {

        .blurb {
            @include typography(subtitle1);
            line-height: 2rem !important;
            white-space: pre-line;
            margin-bottom: 40px;
        
            @media(max-width: breakpoints(md)) {
                padding-top: 40px;
            }
        }
        .graphic {
            .svg {
                width: 100%;
            }
        }
    }
}