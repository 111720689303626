.til {
    @extend .flex-col-center;

    .content{
        justify-content: center;
        @extend .default-margins; 
        @extend .default-padding;

        .grid-item-img {
            @extend .center-content;

            svg {
                max-width: 600px;
                width: 100%;
            }
        }
        .text {
            @extend .flex-column;

            .section {

                .section-title {
                    @include typography(h6);
                    font-size: 1.4rem !important;
                    font-weight: 700 !important;
                    margin-bottom: 20px;
                }
                .body {
                    @include typography(body1);
                    margin-bottom: 20px;
                    white-space: pre-line;
                }
            }
           
        }
        
    }
}