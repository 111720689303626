.contact {
    @extend .flex-col-center;
    background: palette(background, dark);
    padding-top: $header-height;

    .content {
        min-height: calc(100vh - $header-height) !important;

        .contact-overview {
            @extend .flex-column;
            align-items: center;
            justify-content: center;

            @media (max-width: breakpoints(sm)) {
                padding: 50px;
            }

            .title {
                @include typography(h1);
                font-weight: 500 !important;
                color: white;
                
                @media (max-width: breakpoints(md)) {
                    font-size: 2.75rem !important;
                }
                @media (max-width: breakpoints(sm)) {
                    font-size: 2.25rem !important;
                }
            }

            .blurb {
                @include typography(caption);
                padding-bottom: 50px;
                font-size: 1.9rem !important;
                line-height: 2.8rem !important;
                color: white;
            }
            .contact-section {
                @extend .flex-column;
                padding-bottom: 50px;
                white-space: pre-line;

                @media (min-width: breakpoints(lg)) {
                    flex-direction: row;
                }

                .header-box {
                    @extend .flex-column;
                    justify-content: center;
                    padding-bottom: 20px;

                    @media (min-width: breakpoints(lg)) {
                        padding-bottom: 0px;
                    }

                    .contact-header {
                        @include typography(body1);
                        font-weight: 500 !important;
                        text-transform: uppercase;
                        min-width: 100px;
                        color: white;
                    }
                }
                
                .contact-info {
                    @extend .flex-column;
                    border-left: 2px solid white;
                    padding: 0px 20px;
                    overflow-wrap: break-word !important;

                    .contact-line {
                        color: white;
                    }
                }
            } 
        }
        .map {
            min-height: 50vh;
        }
    }
}

.map-container {
    height: 100%;
    width: 100%; 
    
    .map-info-box {
        height: 50px;
        width: 200px;

        .title {
            @include typography(body1);
            font-size: 1rem;
        }
    }
}
