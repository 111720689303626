.scroll-anchor {
    margin-top: -100px;
    padding-top: 100px;
}

.app-content {
    min-height: 100vh !important;
    max-width: 100vw;
}

.check-icon {
    min-width: 1.2rem !important;
    height: 1.2rem !important;
}

.additional-content {
    padding: 20px 40px 20px 20px;

    .img {
        max-width: 100%;
        height: auto;
        max-height: 125px;
        margin-bottom: 50px;

        &.large {
            max-height: 600px;

            &.clickable {
                cursor: pointer;
            }
        }
        &.limit-width {
            max-width: 300px;
        }
    }
    .title {
        @include typography(h6);
        font-weight: bold !important;
        margin-bottom: 10px; 
    }
    .body {
        @include typography(body1);
        white-space: pre-line;
        margin-bottom: 20px;

        .list {
            column-count: 1;

            &.two-columns {
                column-count: 2;
                @media(max-width: breakpoints(sm)){
                    column-count: 1;
                }
            }
            .list-item {
                margin-bottom: 10px;
            }
        }
    }
}