.client-page {
    @extend .flex-col-center;
    
    .content {
        @extend .flex-column;
        @extend .center-content;
        @extend .default-margins;
        @extend .default-padding;

        .m-div {
            width: 100%;
        }
        .quote {
            padding-bottom: 20px;
            font-style: italic;
        }
        .accordion {
            margin-bottom: 20px;
            background-color: white;
            box-shadow: none !important;
            width: 100%;

            @media (max-width: breakpoints(sm)){
                max-width: 400px !important;
            } 

            .accordion-summary {
                border-radius: 2px;
                color: palette(primary, contrastText);
                background-color: palette(primary, main);

                .accordion-summary-icon {
                    color:white; 
                    font-size: 1.75rem; 
                    font-weight: 50;
                }
            }

            .accordion-details {
                margin-top: 10px;
                background-color: white !important;

                .accordion-detail-title {
                    font-style: italic;
                    font-weight: 500;
                    padding-bottom: 10px;
                }
            }
        }
        
        .list {
            padding-top: 10px;    
            .list-item {
                @extend .flex-row;
                justify-content: flex-start;

                .list-text {
                    @extend .body;
                    margin-left: 10px;
                    text-align: left;
                }
            }
        }
    }
}