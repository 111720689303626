.landing {
    @extend .flex-column;
    align-items: center;

    .hero {
        display: flex;
        justify-content: center;
        height: 95vh;
        width: 100%;
        background: url(../../media/landing/hero.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        box-shadow: inset 0 0 0 2000px $hero-img-overlay;
    
        .hero-content {
            @extend .default-margins-xl;
            width: 100%;
            padding-top: $header-height;
    
            .hero-banner {
                @extend .default-padding;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $hero-banner-color;
                height: 100%;
                gap: 40px;
    
                @media (max-width: breakpoints(sm)) {
                    width: 100% !important;
                    margin-left: 0;
                }
                @media (min-width: breakpoints(sm)) {
                    width: max-content;
                    margin-left: 5%;
                }
    
                .hero-text {
                    color: white;
                    font-weight: 500;
                    overflow-wrap: break-word;
                    white-space: pre-line;
    
                    @media (max-width: breakpoints(sm)) {
                        text-align: center;
                    }
                    @media (max-width: breakpoints(md)) {
                        font-size: 3.75rem;
                        line-height: 4.5rem;
                    }
                    @media (min-width: breakpoints(md)) {
                        font-size: 4.25rem;
                        line-height: 5rem;
                    }
                }

                .actions {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    @media (max-width: breakpoints(sm)) {
                        justify-content: center;
                    }
                }

                .MuiButton-outlined {
                    color: white;
                }
            }
        }
    }

    .our-services {
        @extend .flex-column;
        @extend .default-padding;
        @extend .default-margins;
        width: 100%;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 100px;
    
        .title-section {
            @extend .flex-row;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 25px;
            width: 100%;
            padding-bottom: 50px;
    
            @media (max-width: breakpoints(sm)) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;
            }
    
            .section-title {
                @include typography(h2);
    
                @media (max-width: breakpoints(sm)) {
                    padding-bottom: 10px;
                }
            } 
        }
        .list {
            justify-content: center;
            .list-item {
                height: 375px;

                @media (max-width: breakpoints(sm)) {
                    height: 300px;
                }

                .motion-div {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .featured-posts {
        .fp-container {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}