.products {
    @extend .flex-col-center;
    width: 100%;

    .title-section {
        @extend .default-margins;
        @extend .default-padding;
        text-align: center !important;
        width: 100%;

        .title {
            @include typography(h1);
            font-weight: 500 !important;
            margin-bottom: 10px;

            @media (max-width: breakpoints(md)) {
                font-size: 2.75rem !important;
                margin-bottom: 40px;
            }
            @media (max-width: breakpoints(sm)) {
                font-size: 2.25rem !important;
            }
        }
    }
    .content {
        @extend .flex-column;
        align-items: center;
        width: 100%;

        .blurb-bg {
            @extend .full-width;
            @extend .flex-column;
            @extend .center-content;
            border-top: 1px dashed palette(grey, 400);

            .blurb {
                @extend .default-padding;
                @extend .default-margins;
                @include typography(subtitle1);
                text-align: center;
                line-height: 2rem !important;
                white-space: pre-line;
                padding-bottom: 50px !important;
            }
        }
        .product-bg {
            @extend .full-width;
            @extend .flex-row;
            justify-content: center;
            max-height: 100%;
            margin-bottom: 0px;

            @media (max-width: breakpoints(sm)){
                padding: 50px 50px 50px 50px;
            }
            @media (min-width: breakpoints(lg)){
                margin-bottom: 200px;
                &.blue {
                    max-height: 420px;
                }
                &.red {
                    max-height: 340px;
                }
            }

            &.blue {
                background: palette(background, light-gray);
            }
            &.red {
                background: $red-product-bg;
            }
            .prd-content {
                @extend .default-margins;
                @extend .default-padding;
                display: flex;
                margin-bottom: 50px;

                @media (max-width: breakpoints(sm)){
                    padding: 0px !important;
                }

                &.img-right {
                    flex-direction: column;

                    @media (min-width: breakpoints(lg)){
                        flex-direction: row;
                    }
                }

                &.img-left {
                    flex-direction: column;

                    @media (min-width: breakpoints(lg)){
                        flex-direction: row-reverse;
                    }
                }
                .img {
                    @extend .center-content;
                    z-index: 2;
                    margin-top: 50px;
                    align-self: center;
                    
                    @media (max-width: breakpoints(sm)){
                        width: 100%;
                        min-height: auto !important;
                    }
                    @media (max-width: breakpoints(md)){
                        margin-top: 0px;
                    }
                    @media (min-width: breakpoints(lg)) {
                        align-self: flex-start;
                    }

                    &.large {
                        max-width: 600px; 
                        height: auto;
                        min-height: 400px;
                    }
                    &.regular {
                        max-width: 500px; 
                        height: auto;
                        min-height: 350px;
                    }
                }
                .info {
                    @extend .flex-column;
                    border-radius: 4px;

                    .craft-logo {
                        width: 150px;
                    }
                    .info-title {
                        @include typography(h4);
                        font-weight: 700 !important;
                        margin-bottom: 10px;

                        &.red {
                            color: $light-red;
                        }
                        &.blue {
                            color: palette(primary,main);
                        }
                    }
                    .info-summary {
                        @include typography(body1);
                        line-height: 1.8rem !important;
                        white-space: pre-line;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}