.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    @extend .flex-row;
    justify-content: center;
}

.flex-col-center {
    @extend .flex-column;
    align-items: center;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}