.why-join-us {
    @extend .default-margins;

    .default-content {
        @extend .default-padding;
        padding-top: 0px !important;

        .image-list {
            padding: 20px;
            background-color: palette(background, light-gray);
            align-items: center;
    
            .img {
                align-self: center !important;
                max-width: 100%;
                height: auto;
                margin-bottom: 70px;
            }
        }
        .text { 
            padding: 50px;
            padding-top: 20px;

            @media(max-width: breakpoints(md)) {
                padding-left: 0px;
                padding-right: 0px;
                padding-bottom: 0px;
            }

            &.we-offer {
                padding-top: 50px;

                @media(max-width: breakpoints(md)) {
                    padding-top: 0px;
                }
            }
            .title {
                @include typography(h3);
                margin-bottom: 20px;
            }
            .body {
                @include typography(body1);
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            .cut-out {
                @extend .flex-row;
                color: palette(primary,main);
                padding: 50px;
    
                .ct-text {
                    @include typography(caption);
                    margin-left: 20px;
                    padding: 0px;
                }
            }
            .key-points {
                @extend .flex-column;

                .section-title {
                    @include typography(h6);
                    font-weight: bold !important;
                    margin-bottom: 10px; 
                }
                .body {
                    @extend .body;
                    margin-bottom: 40px;
                }
            }
            .list {
                display: grid;
                grid-template-columns: repeat(2, auto [col-start]);
    
                @media(max-width: breakpoints(md)) {
                    grid-template-columns: repeat(1, auto [col-start]);
                }
    
                .list-item {
                    @extend .flex-row;
                    justify-content: flex-start;
                    
                    .list-text {
                        @extend .body;
                        margin-left: 10px;
                        text-align: left;
                    }
                    .illustration {
                        width: 80px !important;
                        height: 80px !important;
                    }
                }
                .desc-section {
                    @extend .flex-row;
                    margin-bottom: 20px;
                    .content {
                        @extend .flex-column;
                        margin-left: 20px;
                        max-width: 70%;
                        .header {
                            @include typography(h6);
                            text-transform: uppercase !important;
                            margin-bottom: 10px;
                        }
                        .body {
                            @include typography(body1);
                        }
                    }
                }
            }
        }
    }
} 