// --------------------- NAVIGATION ---------------------
.navigation {
    &.app-bar {
        height: $header-height;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: palette(background, default);
    }

    .toolbar {
        @extend .default-margins-xl;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
    }

    .app-logo {
        display: flex;
        align-items: center;
        svg {
            width: 300px;
            color: palette(primary, main);

            @media (max-width: breakpoints(sm)) {
                width: 250px;
            }
        }
        :hover {
            cursor: pointer;
        }
    }
}

// --------------------- HEADER ---------------------
.header {
    display: flex;
    height: 100%;

    .header-button-div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .header-button {
            @include typography(button);
            text-transform: capitalize;
            color: palette(text, primary);
            border-radius: 0;
            padding: 10px;
            border-bottom-style: solid;
            border-bottom-width: 2px;
            border-bottom-color: transparent;
    
            &.active {
                color: palette(primary, main);
                border-bottom-color: palette(primary, light);
            }
            &:hover, &.hovered {
                &:not(.active) { // if already active styled, don't do hover style
                    border-bottom-color: palette(text, primary);
                }
            }
        }
    }
}

.header-sub-menu {
    border-radius: 0 !important;

    .header-sub-menu-item {
        &.active {
            font-weight: 500 !important;
        }
    }
}



// --------------------- SIDEBAR ---------------------
.sidebar-toggle-icon {
    color: palette(text, primary);
}

.sidebar-drawer {
    width: 100%;
    max-width: 300px;

    .sidebar-actions { // align icon button with toggle
        height: $header-height;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 15px;
    }

    .sidebar-list {
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
    }

    .sidebar-list-item {
        color: palette(text, primary) !important;
        &.active {
            border: 2px solid palette(primary, light);
            .MuiTypography-root {
                color: palette(primary, main);
                font-weight: bold;
            }
        }
    }

    .sidebar-sub-list-item {
        color: palette(text, primary) !important;
        &.active {
            border-left: 2px solid palette(primary, light);
            .MuiTypography-root {
                color: palette(primary, main);
                font-weight: bold;
            }
        }
    }
}