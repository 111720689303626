.service-card {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    transition: all 2s ease-in-out;
    filter: grayscale(30%);

    .card-content {
        @extend .center-content;
        @extend .flex-column;
        background: $service-card-overlay;
        border: 3px solid $secondary-gradient;
        text-align: center;
        padding: 25px;
        height: 100%;
        width: 100%;


        .service-title {
            @include typography(h3);
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $white;
        }
        .card-divider {
            @extend .center-content;
            margin-top: 15px;
            display: flex;
            width: 100%;

            .dots {
                display: hidden !important;
                border-top: 8px dotted $white;
                height: 1px;
                width: 0px;
                transition: all .5s ease-in-out;  
            }
        }
    }
}

.service-card:hover {
    cursor: pointer;

    .card-divider {
        .dots {
            width: 150px !important;
            display: inherit;
        }
    }
}