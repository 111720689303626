.featured-posts {
    @extend .flex-col-center;
    width: 100%;

    &.dark {
        background-color: palette(background, dark);
        padding-top: $header-height;

        .fp-title {
            color: white;
        }
    }
    &.light {
        background-color: palette(background, light-gray);
    }
    .fp-container {
        @extend .flex-column;
        @extend .default-margins;
        @extend .default-padding;
        width: 100%;

        .fp-title {
            padding-bottom: 20px;
            @include typography(h2);
        }
        .fp-post-motion-div {
            width: 100%;
            height: 100%;
        }
        .fp-btn-div {
            @extend .flex-row;
            justify-content: flex-end;
            width: 100%;
            margin-top: 40px;
        }
    }
}