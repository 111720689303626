@import url("https://use.typekit.net/lfd0gqg.css");

$typography: (
    h1: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 400,
        fontSize: 3.25rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    h2: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 500,
        fontSize: 2.75rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    h3: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 700,
        fontSize: 2.25rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    h4: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 500,
        fontSize: 1.75rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    h5: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 600,
        fontSize: 1.5rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    h6: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 500,
        fontSize: 1.25rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    subtitle1: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 300,
        fontSize: 1.5rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    subtitle2: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 300,
        fontSize: 1.25rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    body1: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 400,
        fontSize: 1.1rem,
        lineHeight: 1.6rem,
        letterSpacing: normal,
    ),
    body2: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 400,
        fontSize: 0.9rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    button: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 500,
        fontSize: 1.1rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    caption: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 300,
        fontSize: 2rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
    overline: (
        fontFamily: ('interstate', sans-serif),
        fontWeight: 400,
        fontSize: 0.95rem,
        lineHeight: normal,
        letterSpacing: normal,
    ),
);