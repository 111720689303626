@import './featured_posts', './post_card', './post_details';

.news-page {
    @extend .flex-col-center;
    padding-top: $header-height;

    .pl-padding-margins {
        @extend .default-margins-xl;
        padding-left: 80px;
        padding-right: 80px;
        @media (max-width: breakpoints(md)) {
            padding-left: 40px;
            padding-right: 40px;
        }
        @media (max-width: breakpoints(sm)) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .functions {
        @extend .pl-padding-margins;
        padding-top: 50px;
        padding-bottom: 50px;
        justify-content: space-between;
        
        &.single {
            justify-content: center;
        }
        @media (max-width: 865px) {
            justify-content: center;
        }
        .search-filter {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 600px) {
                flex-direction: column;
                width: 100%;
            }
    
            .search {
                width: 250px;
                max-width: 100%;
                margin-bottom: 10px;
                background-color: white;
            }
            .filter {
                @extend .search;
                @media (min-width: 601px) {
                    margin-right: 20px;
                }
            }
        }
    }
    .post-grid {
        @extend .pl-padding-margins;
        @extend .scroll-anchor;
    }
}