.internship {
    @extend .flex-col-center;

    .overview {
        justify-content: center;
        @extend .default-margins;
        @extend .default-padding;
    
        .graphic-section {
            justify-content: center;
            align-items: flex-start;
            
            @media (min-width: breakpoints(md)) {
                padding-right: 40px;
            }
            @media (max-width: breakpoints(md)) {
                padding-bottom: 40px;
            }

            img {
                max-width: 100%;
            }
        }
        .body {
            margin-bottom: 20px;
            white-space: pre-line;
        }
        .list-item {
            @extend .flex-row;
            justify-content: flex-start;
            flex-wrap: wrap;

            .list-text {
                margin-left: 10px;
                text-align: left;
            }
        }
    }
}
