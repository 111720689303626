.logo-list {
    @extend .default-logo-padding;
    @extend .flex-column;
    @extend .center-content;
    width: 100%;

    .section-title {
        @include typography(h3);
        margin-bottom: 20px;
        text-align: center;
    }
    .crt-section-title {
        @include typography(h4);
        font-weight: 700 !important;
        text-transform: uppercase !important;            
        text-align: left;
        &.left {
            text-align: left;
        }
    }
    .certs {
        @extend .flex-row;
        @extend .center-content;
        flex-wrap: wrap;

        .cert-img {
            width: 100%;
            max-width: 200px;
            padding: 2%;
        }
    }

    .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 50px 0;
    }
}