@import './ceo_message', './our_culture';

.about {
    @extend .flex-col-center;
    
    .abt-section-title {
        @include typography(h4);
        font-weight: 700 !important;
        text-transform: uppercase !important;
        text-align: center;
        margin-bottom: 40px;

        &.left {
            text-align: left;
        }
    }

    .additional-content {
        @extend .additional-content;
        padding: 0px !important;
    }

    .overview {
        justify-content: center;
        @extend .default-margins;
        @extend .default-padding;
    
        .graphic-section {
            justify-content: center;
            align-items: flex-start;
            
            @media (min-width: breakpoints(md)) {
                padding-right: 40px;
            }
            @media (max-width: breakpoints(md)) {
                padding-bottom: 40px;
            }
    
            .about-graphic {
                width: 100%;
            }
        }
        .body {
            margin-bottom: 20px;
            white-space: pre-line;
        }
    }
}