.ceo-message {
    @extend .default-margins;
    @extend .default-padding;
    
    // override sm default padding--allow box  to touch edges
    @media(max-width: breakpoints(sm)) {
        padding: 50px 0px 0px 0px !important;
    }

    .box {
        background-color: palette(background, light-gray);
        border-radius: 5px;
        padding: 40px;

        .section-title {
            @extend .abt-section-title;
            text-align: left !important;

            @media(max-width: breakpoints(md)) {
                text-align: center !important;
            }
        }
        
        .content {
            @extend .flex-row;

            @media(max-width: breakpoints(md)){
                display: flex;
                flex-direction: column;
            }
            
            .photo-section {
                @extend .flex-column;
                margin-right: 50px;

                @media(max-width: breakpoints(md)) {
                    align-items: center;
                    justify-content: center;
                    text-align: center !important;
                    margin: 0px 0px 50px 0px;
                }

                .caption {
                    @extend .flex-column;
                    margin-top: 10px;
                }
            }
            .body {
                @include typography(body1);

                &.bold {
                    font-weight: 600 !important;
                }
            }
        }
    }
}