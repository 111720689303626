.services {
    @extend .flex-col-center;

    .page-content {
        @extend .default-margins;
        @extend .default-padding;
        width: 100%;
    }

    .tabs {
        border-bottom: 1px solid palette(gray, 300);

        .tab {
            @include typography(button);
            @media (max-width: breakpoints(sm)) {
                max-width: min-content;
            }

            &:not(.Mui-selected) {
                color: palette(text, disabled);
            }
        }
    }

    .additional-content {
        @extend .additional-content;
        padding: 0px !important;
    }
}

.service-display {
    @extend .default-padding;
    padding-top: 75px;

    @media (max-width: breakpoints(sm)){
        padding: 50px 0px;
    }

    .text {
        .section-title {
            @include typography(h3);
            margin-bottom: 20px;
        }
        .section-summary {
            @include typography(body1);
            line-height: 1.8rem !important;
            margin-bottom: 20px;

            .list-item {
                @extend .flex-row;
                align-items: center;
                margin-bottom: 10px;

                .bullet-div {
                    align-self: flex-start;
                
                    .bullet {
                        font-size: 11px !important;
                        margin-right: 10px;
                        color: palette(primary, dark);
                    }
                }
            }
        }
    }
    .illustration {
        @extend .center-content; 
        padding-bottom: 50px;
    }
}