.carousel {
    @extend .flex-column;
    @extend .center-content;
    @extend .default-margins;
    width: 100% !important;
    padding: 50px;
    overflow: hidden;

    @media (max-width: breakpoints(sm)) {
        padding: 20px;
        padding-top: 50px;
    }

    .nav {
        @extend .flex-column;
        @extend .center-content;

        @media (max-width: breakpoints(sm)) {
            height: 40vh;
        }

        .box {
            @extend .flex-row;
            justify-content: space-between;
            height: 90%;
            width: 100%;

            .icon-btn {
                height: 40px;
                width: 40px;
                align-self: center;
                z-index: 5;
            }
            .img-div {
                max-width: 100%;
                height: auto;
                overflow: hidden;
            }
            
        }
        .stepper {
            padding-top: 20px;
            justify-content: center;
            background-color: transparent;
        }
    }
    
    .info {
        padding: 40px;
        .info-title {
            @include typography(h3);
            margin-bottom: 20px;
            min-height: 20px;
        }
        .info-summary {
            @include typography(body1);
            line-height: 1.4rem !important;
            margin-bottom: 20px;
        }
    }
}   