.not-found {
    @extend .flex-column;
    @extend .center-content;
    @extend .default-padding;
    padding-top: $header-height !important;
    min-height: 100vh;

    .text{
        padding-top: 20px;
        padding-bottom: 40px;
        text-align: center;

        .h1 {
            @include typography(h3);
            font-size: 6rem !important;
            font-weight: 800 !important;

            @media(max-width: breakpoints(sm)) {
                font-size: 4rem !important;
                margin-bottom: 20px;
            }
        }
        .h2 {
            @include typography(caption);

            @media(max-width: breakpoints(sm)) {
                font-size: 1.8rem !important;
            }
        }
    }

    .home-btn {
        color: palette(text, primary);
        border: 2px solid palette(text, primary);
    }

    .home-btn:hover {
        background-color: palette(secondary, main) !important;
        color: white !important;
        border: 2px solid transparent;
    }
}