@use '../2-basics/typography' as *;

@mixin typography($variant) {
    @if map-has-key($typography, $variant) {
        font-family: map-get(map-get($typography, $variant), fontFamily) !important;
        font-weight: map-get(map-get($typography, $variant), fontWeight) !important;
        font-size: map-get(map-get($typography, $variant), fontSize) !important;
        line-height: map-get(map-get($typography, $variant), lineHeight) !important;
        letter-spacing: map-get(map-get($typography, $variant), letterSpacing) !important;
    } @else {
        @warn "`#{$variant}` does not match the available typography variants";
    }
}