// primary
$light-blue: #1F75C9;
$mid-blue: #003B77;
$dark-blue: #051A2F;

// secondary
$light-green: #77AE5B;
$mid-green: #127859;

// tertiary
$light-red: #D64A38;
$dark-red: #8f1e16;

$white: #ffffff;
$black: #000000;

$palette: (
    primary: (
        light: $light-blue,
        main: $mid-blue,
        dark: $dark-blue,
        contrastText: #FFFFFF,
    ),
    secondary: (
        light: $light-green,
        main: $mid-green,
        dark: darken($mid-green, 12%),
        contrastText: #FFFFFF,
    ),
    text: (
        primary: #484848,
        secondary: #59616C,
        disabled: #979797,
    ),
    background: (
        default: #FFFFFF,
        light-gray: #F2F3F5,
        dark-gray: #24384D,
        dark: #051A2F,
    ),
    grey: (
        50: #fafafa,
        100: #f5f5f5,
        200: #eeeeee,
        300: #e0e0e0,
        400: #bdbdbd,
        500: #9e9e9e,
        600: #757575,
        700: #616161,
        800: #424242,
        900: #212121,
        A50: #f0f0f0,
        A100: #d5d5d5,
        A200: #aaaaaa,
        A300: #969696,
        A400: #808080,
        A600: #686868,
        A700: #525252,
    ),
);

$hero-banner-color: #003B77c7;
$hero-img-overlay: #1358c713;

$page-header-overlay-dark: #004c9781;
$page-header-overlay-default: #1762ae75;

$service-card-overlay: #00243f6a;

$red-product-bg: #f9eeee;

$post-detail-border: #dfe2e5;
$post-detail-table: #f6f8fa;

$primary-gradient: linear-gradient(135deg, palette(primary, main) 0%, palette(primary, light) 100%);
$secondary-gradient: linear-gradient(135deg, palette(secondary, dark) 0%, palette(secondary, light) 100%);
$red-gradient: linear-gradient(135deg, $dark-red 0%, $light-red 100%);
