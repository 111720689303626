// TODO: modify default margins/padding style
.default-margins {
    max-width: breakpoints(lg) !important;
}
.default-margins-xl {
    max-width: breakpoints(xl) !important;
}

.default-padding {
    padding: 50px 100px;
    
    @media (max-width: breakpoints(sm)) {
        padding: 50px 25px;
    }
}

.default-logo-padding {
    padding: 50px 50px;

    @media(max-width: breakpoints(sm)) {
        padding: 50px 50px 10px 50px;
    }

    @media(min-width: breakpoints(lg)) {
        padding: 50px;
    }

    @media(min-width: breakpoints(xl)) {
        padding: 50px 0px;
    }
}

.full-width {
    width: 100% !important;
}