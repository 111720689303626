.our-culture {
    @extend .flex-column;
    @extend .default-margins;
    @extend .default-padding;
    background-color: palette(background, light-gray); 

    .content {
        .top {
            padding-bottom: 50px;
        }
        .title {
            @include typography(h6);
            font-size: 1.4rem !important;
            font-weight: 700 !important;
            margin-bottom: 20px;
        }
        .body {
            @include typography(body1);
            margin-bottom: 20px;
            white-space: pre-line;

            &.hand-written {
                @include typography(caption);
                font-size: 1.5rem !important;
                font-style: italic;
            }

            &.bold {
                font-weight: 600 !important;
            }
        }
        .great-work {
            @extend .flex-column;
            @media(max-width: breakpoints(md)){
                margin-bottom: 50px;                   
            }
        }
        .quality-pledge {
            @extend .flex-row;
            border-radius: 4px;
            padding: 25px;
            background: $primary-gradient;
            color: white;
            margin-bottom: 50px;

            @media(max-width: breakpoints(sm)){
                flex-direction: column;  
                text-align: center !important;                 
            }

            .illustration {
                margin-right: 20px;

                @media(max-width: breakpoints(sm)){
                    display: flex;
                    justify-content: center;   
                    width: 100% !important;   
                    margin-right: 0px;   
                    margin-bottom: 20px;
                }
                
                .icon {
                    width: 80px;
                }
            }
        }
        .mission-values {
            @extend .flex-column;
            width: 100%;
        }
        .our-team {
            @extend .flex-column;
        }
        .list {
            .list-item {
                @extend .flex-row;
                justify-content: flex-start;
                
                .list-text {
                    @extend .body;
                    margin-left: 10px;
                    text-align: left;
                }
            }
        }
    }
}